import React from "react";
import {
  checkAllStarPermission,
  checkCodeExamyPermission,
  checkContractShareWithParent,
  checkIsConservator,
  checkIsStudent,
  checkLiveExamyPermission,
  checkPreferenceMaticPermission,
  checkSchoolPermissions,
  checkSupereadPermission,
  checkTracklightPermission
} from "helpers/StudentCheckPersmissions";
// import { ClientEnums } from "./enums/ClientEnum";
import { SchoolTypeEnum } from "./enums/SchoolTpyeEnum";
const ClubStudentDashboard = React.lazy(() =>
  import("views/StudentSide/ClubStudentDashboard/ClubStudentDashboard")
);
const AllStar = React.lazy(() => import("views/StudentSide/AllStar/AllStar"));
const InstantMessage = React.lazy(() => import("views/AdminSide/InstantMessage/InstantMessage"));
const NearPod = React.lazy(() => import("views/StudentSide/NearPod/NearPod"));
const StudentDashboard = React.lazy(() => import("views/StudentSide/Dashboard/StudentDashboard"));
const Superead = React.lazy(() => import("views/StudentSide/Superead/Superead"));
const ExamResults = React.lazy(() => import("views/StudentSide/ExamResults/StudentExamResults"));
const StudentProfile = React.lazy(() => import("views/StudentSide/Dashboard/StudentProfile"));
const Etude = React.lazy(() => import("views/StudentSide/Etude/Etude"));
const Counselling = React.lazy(() => import("views/StudentSide/Counselling/Counselling"));
const Message = React.lazy(() => import("views/AdminSide/Announcement/Message"));
const StudyRoom = React.lazy(() => import("views/StudentSide/StudyRoom/StudyRoom"));
const StudentStudy = React.lazy(() => import("views/StudentSide/StudentStudy/StudentStudy"));
const StudentTrackLight = React.lazy(() =>
  import("views/StudentSide/TrackLight/StudentTrackLight")
);
const Club = React.lazy(() => import("views/StudentSide/Club/Club"));
const OutSide = React.lazy(() => import("views/StudentSide/Outside/OutSide"));
const StudentNoteList = React.lazy(() => import("views/StudentSide/StudentNotes/StudentNotes"));
const CodeExamy = React.lazy(() => import("views/StudentSide/CodeExamy/CodeExamy"));
const Survey = React.lazy(() => import("views/StudentSide/Survey/Survey"));
const StudentPreferenceMatic = React.lazy(() =>
  import("views/StudentSide/Dashboard/StudentPreferenceMatic")
);
const PaymentList = React.lazy(() => import("views/StudentSide/PaymentList/PaymentList"));
const Livexamy = React.lazy(() => import("views/Common/Livexamy/Livexamy"));
const OnlineExam = React.lazy(() => import("views/StudentSide/OnlineExam/OnlineExam"));
const SelfIdStudent = React.lazy(() => import("views/StudentSide/SelfIdStudent/SelfIdStudent"));

//TODO: eskisi, daha sonra silinecek
//const LiveExamy = React.lazy(() => import("views/StudentSide/LiveExamy/LiveExamy"));
//const SinavDigital = React.lazy(() => import("views/StudentSide/SinavDigital"));
//const SinavGlobal = React.lazy(() => import("views/StudentSide/SinavGlobal"));

const StudentRouteList = [
  {
    id: "6232b52cc783c144a031a000",
    icon: "/media/svg/topMenu/dashboard.svg",
    menu: true,
    name: "Giriş",
    route: "dashboard",
    color: "#F9A826",
    component: StudentDashboard
  },
  {
    id: "6232b52cc783c144a031a000",
    icon: "/media/svg/topMenu/selfid-icon.svg",
    menu: true,
    name: "THE Self ID",
    route: "the-self-id",
    color: "#F9A826",
    component: SelfIdStudent
  },
  {
    id: "636e5555caae714b4aea0404",
    icon: "/media/bottom-menu/study-room.png",
    menu: true,
    name: "Çalışma Odam",
    route: "study-room",
    color: "#F9A826",
    component: StudyRoom
  },
  {
    id: "636e4463ffae714b4aea0404",
    icon: "",
    menu: false,
    name: "Online Sınav",
    route: "online-exam",
    color: "",
    component: OnlineExam
  },
  // {
  //   id: "6232c52ec783c144a031a888",
  //   icon: "/media/svg/topMenu/livexamy.svg",
  //   menu: true,
  //   name: "Livexamy",
  //   route: "liveexamy",
  //   color: "#F94C6E",
  //   component: LiveExamy,
  //   checkPermission: checkLiveExamyPermission
  // },
  {
    id: "6232b52cc783c144a031a000",
    icon: "/media/svg/topMenu/livexamy.svg",
    menu: true,
    name: "Livexamy",
    route: "livexamy-premium",
    color: "#F9A826",
    component: Livexamy,
    checkPermission: checkLiveExamyPermission
  },
  {
    id: "6232b52cc783c144a031a7652",
    icon: "/media/bottom-menu/prefrobot.png",
    menu: true,
    name: "CodeExamy",
    route: "codeexamy",
    color: "#F9A826",
    component: CodeExamy,
    checkPermission: checkCodeExamyPermission
  },

  // {
  //   // id: "8232c52cc783c144a031a788",
  //   icon: "/media/bottom-menu/dijital.png",
  //   menu: false,
  //   name: "SınavDijital",
  //   route: "sinav-digital",
  //   color: "#F9A826",
  //   component: SinavDigital,
  //   permission: ClientEnums.READING_MACHINE,
  //   checkPermission: checkReadingMachinePermission
  // },
  // {
  //   id: "6232b52cc783c144a031a999",
  //   icon: "/media/svg/topMenu/digital.svg",
  //   name: "Dijital",
  //   color: "#F9A826",
  //   menu: true,
  //   route: "",
  //   subroute: [
  //     {
  //       // id: "8232c52cc783c144a031a788",
  //       icon: "/media/bottom-menu/dijital.png",
  //       menu: true,
  //       name: "SınavDijital",
  //       route: "sinav-digital",
  //       color: "#F9A826",
  //       component: SinavDigital,
  //       permission: ClientEnums.READING_MACHINE,
  //       checkPermission: checkReadingMachinePermission
  //     },
  //     {
  //       // id: "8232b44bc783c144a031a788",
  //       icon: "/media/bottom-menu/global.png",
  //       menu: true,
  //       name: "SınavGlobal",
  //       route: "sinav-global",
  //       color: "#F9A826",
  //       component: SinavGlobal,
  //       permission: ClientEnums.GLOBAL_MACHINE,
  //       checkPermission: checkSinavGlobalPermission
  //     },
  //     {
  //       icon: "/media/client-logos/eng-file.png",
  //       name: "English File",
  //       color: "#F9A826",
  //       route: "http://englishfileonline.com",
  //       permission: ClientEnums.ENGFile2022
  //     },
  //     {
  //       icon: "/media/client-logos/matific.png",
  //       name: "Matific",
  //       color: "#F9A826",
  //       route: "https://www.matific.com/tr/tr/login-page/",
  //       permission: ClientEnums.Matific2022
  //     },
  //     {
  //       icon: "/media/client-logos/sinav-tms.png",
  //       name: "SINAV TMS",
  //       color: "#F9A826",
  //       route: "https://lms.teamelt.com/login",
  //       permission: ClientEnums.SINAVTMS2022
  //     },
  //     {
  //       icon: "/media/client-logos/more-more.png",
  //       name: "More More",
  //       color: "#F9A826",
  //       route: "http://www.kurmayokul.com/main-page-login.php?account=login",
  //       permission: ClientEnums.MOREMORE2022
  //     },
  //     {
  //       icon: "/media/client-logos/oxford.png",
  //       name: "Oxford Discover",
  //       color: "#F9A826",
  //       route: "https://oxforddiscover2e.oxfordonlinepractice.com/app/",
  //       permission: ClientEnums.OXFORD2022
  //     },
  //     {
  //       icon: "/media/client-logos/project-explore.png",
  //       name: "Project Explore",
  //       color: "#F9A826",
  //       route: "https://projectexploreonline.com",
  //       permission: ClientEnums.PROJECTEXP2022
  //     },
  //     {
  //       icon: "/media/client-logos/almanca.png",
  //       name: "Eradil LMS",
  //       color: "#F9A826",
  //       route: "https://www.eradillms.com/giris_yap.php",
  //       permission: ClientEnums.ALMANCALMS2022
  //     }
  //   ]
  // },

  {
    id: "6264b52cc783c144a055a7651",
    icon: "/media/bottom-menu/outside.png",
    menu: true,
    name: "Outside",
    color: "#F9A826",
    route: "outside",
    component: OutSide,
    permission: [
      SchoolTypeEnum.PRIMARY,
      SchoolTypeEnum.KREŞ,
      SchoolTypeEnum.ANAOKULU,
      SchoolTypeEnum.ILKOKUL,
      SchoolTypeEnum.ORTAOKUL,
      SchoolTypeEnum.ANADOLU_LISESI,
      SchoolTypeEnum.FEN_LISESI
    ],
    checkPermission: checkSchoolPermissions
  },
  {
    id: "6264b5dcc783c144a011a765",
    icon: "/media/svg/topMenu/results.svg",
    color: "#F9A826",
    menu: true,
    name: "Sonuçlarım",
    route: "exam-results",
    component: ExamResults
  },
  {
    id: "6264b5dcc783c199a031a755",
    icon: "/media/svg/topMenu/clubs.svg",
    color: "#F9A826",
    menu: true,
    name: "Kulüpler",
    route: "club",
    component: Club,
    permission: [
      SchoolTypeEnum.ANADOLU_LISESI,
      SchoolTypeEnum.ANAOKULU,
      SchoolTypeEnum.FEN_LISESI,
      SchoolTypeEnum.ILKOKUL,
      SchoolTypeEnum.ORTAOKUL,
      SchoolTypeEnum.PRIMARY
    ],
    checkPermission: checkSchoolPermissions
  },
  {
    id: "6264b5dcc783c144a031a755",
    icon: "/media/svg/topMenu/etudes.svg",
    color: "#F9A826",
    menu: true,
    name: "Etütlerim",
    route: "etude",
    component: Etude
  },
  {
    id: "6264b5dcc783c144a031a735",
    icon: "/media/bottom-menu/notes.png",
    color: "#E92663",
    menu: false,
    name: "Notlarım",
    route: "notes",
    component: StudentNoteList
  },
  {
    id: "6264b5dcc783c554b331a735",
    icon: "/media/svg/topMenu/counselling.svg",
    color: "#FFCC75",
    menu: true,
    name: "Rehberlik",
    route: "counselling",
    component: Counselling,
    checkPermission: checkIsConservator
  },
  {
    id: "ffec22dcc783c554b331a735",
    icon: "/media/svg/icons/message.svg",
    color: "#FFCC75",
    menu: true,
    name: "Mesaj",
    route: "message",
    component: Message
  },
  {
    id: "6244cedcc783c554b331a735",
    icon: "/media/svg/topMenu/surveys.svg",
    color: "#FFCC75",
    menu: false,
    name: "Anket",
    route: "survey",
    component: Survey,
    checkPermission: checkIsStudent
  },
  {
    id: "6264b5dcc783c144a031a725",
    icon: "",
    menu: false,
    name: "Profil",
    route: "profile",
    component: StudentProfile
  },
  {
    id: "6264b5dcc783c144a031a711",
    icon: "",
    menu: false,
    name: "",
    route: "study",
    component: StudentStudy
  },
  {
    id: "636e3301cfba714b4aea0404",
    icon: "/media/menuItems/tracklight.svg",
    menu: true,
    name: "TrackLight",
    route: "track-light",
    color: "#F9A826",
    component: StudentTrackLight,
    checkPermission: checkTracklightPermission
  },
  {
    id: "6264b5dcc783c144a031a712",
    icon: "/media/svg/topMenu/preferance.svg",
    menu: false,
    name: "Tercihler",
    route: "preferencematic",
    component: StudentPreferenceMatic,
    permission: ["8", "12", "60", "100"],
    checkPermission: checkPreferenceMaticPermission
  },
  {
    id: "6264b52cc783c144a031a76513",
    icon: "/media/bottom-menu/superead.png",
    menu: true,
    name: "Superead",
    route: "superead",
    color: "#3E99B6",
    component: Superead,
    checkPermission: checkSupereadPermission
  },
  {
    id: "62ffeffcc783c144a031a000",
    icon: "/media/bottom-menu/accounting.png",
    menu: true,
    name: "Ödeme Planı",
    route: "payment",
    color: "#F9A826",
    component: PaymentList,
    checkPermission: checkContractShareWithParent
  },
  {
    id: "6264b52cc783c144a055a7651",
    icon: "/media/bottom-menu/outside.png",
    menu: false,
    name: "NearPod",
    color: "#F9A826",
    route: "nearpod",
    component: NearPod
  }
];

const ClubStudentRouteList = [
  {
    id: "6232b52cc783c144a031a000",
    icon: "/media/svg/topMenu/dashboard.svg",
    menu: true,
    name: "Giriş",
    route: "dashboard",
    color: "#F9A826",
    component: ClubStudentDashboard
  },
  {
    id: "6232b52cc783c144a031a000",
    icon: "/media/svg/topMenu/livexamy.svg",
    menu: true,
    name: "Livexamy",
    route: "livexamy-premium",
    color: "#F9A826",
    component: Livexamy,
    checkPermission: checkLiveExamyPermission
  },
  {
    id: "ffec22dcc783c554b331a735",
    icon: "/media/svg/icons/message.svg",
    color: "#FFCC75",
    menu: true,
    name: "SMS",
    route: "message",
    component: Message
  },
  {
    id: "6232b52cc783c144a031a000",
    icon: "/media/svg/icons/message.svg",
    menu: true,
    name: "Mesajlar",
    route: "instant-message",
    color: "#F9A826",
    component: InstantMessage
  },
  {
    id: "6264b5dcc783c144a031a725",
    icon: "",
    menu: false,
    name: "Profil",
    route: "profile",
    component: StudentProfile
  },
  {
    id: "6264b5dcc783c144a031a735",
    icon: "/media/bottom-menu/notes.png",
    color: "#E92663",
    menu: false,
    name: "Notlarım",
    route: "notes",
    component: StudentNoteList
  },
  {
    id: "6264b5dcc783c144a031a712",
    icon: "/media/svg/topMenu/preferance.svg",
    menu: false,
    name: "Tercihler",
    route: "preferencematic",
    component: StudentPreferenceMatic,
    permission: ["8", "12", "60", "100"],
    checkPermission: checkPreferenceMaticPermission
  },
  {
    id: "6232b52cc783c144a031a000",
    icon: "/media/svg/topMenu/selfid-icon.svg",
    menu: true,
    name: "THE Self ID",
    route: "the-self-id",
    color: "#F9A826",
    component: SelfIdStudent
  },
  {
    id: "6232b52cc783c144a031a12300",
    icon: "/media/svg/topMenu/star.svg",
    menu: true,
    name: "AllStar",
    route: "allStar",
    color: "#F9A826",
    component: AllStar,
    checkPermission: checkAllStarPermission
  }
];

export { StudentRouteList, ClubStudentRouteList };
